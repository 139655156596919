// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".claim-conditions-services_toggle_101350cc {\n    margin: -8px auto 0;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.claim-conditions-services_tooltip_7938e6f0 {\n    padding-left: 4px;\n    font-weight: 400;\n}\n\n.claim-conditions-services_open_2bb09e4b {\n    transition: 0.2s;\n    transform: rotate(270deg);\n}\n\n.claim-conditions-services_closed_cf3143a4 {\n    transition: 0.2s;\n    transform: rotate(90deg);\n}", "",{"version":3,"sources":["webpack://../../libs/claims/src/view/claim-form/claim-conditions-services/existing-incidents.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,wBAAwB;AAC5B","sourcesContent":[".toggle {\n    margin: -8px auto 0;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.tooltip {\n    padding-left: 4px;\n    font-weight: 400;\n}\n\n.open {\n    transition: 0.2s;\n    transform: rotate(270deg);\n}\n\n.closed {\n    transition: 0.2s;\n    transform: rotate(90deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": "claim-conditions-services_toggle_101350cc",
	"tooltip": "claim-conditions-services_tooltip_7938e6f0",
	"open": "claim-conditions-services_open_2bb09e4b",
	"closed": "claim-conditions-services_closed_cf3143a4"
};
export default ___CSS_LOADER_EXPORT___;

import PropTypes from 'prop-types';

import { CAT, DOG } from '@pumpkincare/shared';
import { Body1, Body2, LegalBody } from '@pumpkincare/shared/ui';

import styles from './wellness-club-benefits-overview.css';

const BREED_MAP = {
  [DOG]: {
    savings: '$360',
  },
  [CAT]: {
    savings: '$395',
  },
};

function WellnessClubBenefitsOverview({ species }) {
  return (
    <div className={styles.entitlementsSection}>
      <div className={styles.entitlementsBox}>
        {species ? (
          <div className={styles.boxHeader}>
            <Body1 isBold>
              Save up to {BREED_MAP[species].savings}+/year with your Pumpkin
              Wellness Club benefits
            </Body1>
          </div>
        ) : null}
        <div className={styles.boxContent}>
          <LegalBody isBold>Reimbursable Vet Services</LegalBody>

          <div className={styles.entitlementItem}>
            <img src='/assets/images/iconImages/check-black.svg' alt='' />
            <Body2>Wellness Exams</Body2>
          </div>

          <div className={styles.entitlementItem}>
            <img src='/assets/images/iconImages/check-black.svg' alt='' />
            <Body2>Vaccinations</Body2>
          </div>

          <div className={styles.entitlementItem}>
            <img src='/assets/images/iconImages/check-black.svg' alt='' />
            <Body2>Health Screening Tests</Body2>
          </div>

          <div className={styles.entitlementItem}>
            <img src='/assets/images/iconImages/check-black.svg' alt='' />
            <Body2>Tick/Flea/Heartworm Prevention</Body2>
          </div>

          <div className={styles.entitlementItem}>
            <img src='/assets/images/iconImages/check-black.svg' alt='' />
            <Body2>Spay/Neuter OR Routine Dental Cleaning</Body2>
          </div>

          <LegalBody isBold className={styles.sectionTitle}>
            Freebies for Club Members{' '}
            <img
              src='/assets/images/iconImages/design-star.png'
              alt=''
              className={styles.starIcon}
            />
          </LegalBody>

          <div className={styles.entitlementItem}>
            <img src='/assets/images/iconImages/check-black.svg' alt='' />
            <Body2>24/7 Pet Health Helpline</Body2>
          </div>

          <div className={styles.entitlementItem}>
            <img src='/assets/images/iconImages/check-black.svg' alt='' />
            <Body2>Access to Discounts on Top Pet Brands</Body2>
          </div>
        </div>
      </div>
    </div>
  );
}

WellnessClubBenefitsOverview.defaultProps = {
  species: '',
};

WellnessClubBenefitsOverview.propTypes = {
  species: PropTypes.oneOf([DOG, CAT]),
};
export default WellnessClubBenefitsOverview;

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useBooleanInput } from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  Checkbox,
  ChevronRight,
  Tooltip,
} from '@pumpkincare/shared/ui';

import styles from './existing-incidents.css';

/*
   if open, show all incidents
   if closed, show 5 incidents or more if 6+ are selected
   move selected incidents to the top
 */
export function formatIncidentsToShow(incidents, selectedState, isShowAllToggle) {
  const { selected, unselected } = incidents.reduce(
    (result, incident) => {
      const key = selectedState[incident.sub_diagnosis.id]
        ? 'selected'
        : 'unselected';

      result[key].push(incident);

      return result;
    },
    { selected: [], unselected: [] }
  );

  const isShowAll = incidents.length <= 5 || isShowAllToggle;
  if (isShowAll) return [...selected, ...unselected];

  const unselectedToAdd = Math.max(0, 5 - selected.length);
  return [...selected, ...unselected.slice(0, unselectedToAdd)];
}

function ExistingIncidents({
  classes,
  onIncidentCheckboxChange,
  incidents,
  selectedState,
}) {
  const [isShowAllIncidents, toggleShowAllIncidents] = useBooleanInput(false);

  return (
    <div className={classes.checkboxes} style={{ marginTop: '16px' }}>
      <Body2 isBold style={{ display: 'flex' }}>
        Previously identified
        <div className={styles.tooltip}>
          <Tooltip>
            Conditions you've selected in a previous claim and those found by our
            claims team in your pet's records
          </Tooltip>
        </div>
      </Body2>
      {formatIncidentsToShow(incidents, selectedState, isShowAllIncidents).map(
        ({ sub_diagnosis: { id, name } }, idx) => (
          <div className={classes.checkboxWrapper} key={id}>
            <Checkbox
              id={id}
              name={id}
              label={name}
              onChange={onIncidentCheckboxChange}
              checked={!!selectedState[id]}
              classes={{ root: classes.checkbox }}
              data-testid={`existingIncident-${idx}`}
            />
          </div>
        )
      )}

      {incidents.length > 5 ? (
        <button
          type='button'
          className={classNames(styles.toggle, ButtonStyles.tertiary)}
          onClick={toggleShowAllIncidents}
        >
          {isShowAllIncidents ? 'View Less' : 'View All'}
          <ChevronRight
            width={8}
            height={14}
            className={isShowAllIncidents ? styles.open : styles.closed}
          />
        </button>
      ) : null}
    </div>
  );
}

ExistingIncidents.defaultProps = {
  classes: {},
};

ExistingIncidents.propTypes = {
  classes: PropTypes.shape({
    checkboxes: PropTypes.string,
    checkboxWrapper: PropTypes.string,
    checkbox: PropTypes.string,
  }),
  onIncidentCheckboxChange: PropTypes.func.isRequired,
  incidents: PropTypes.array.isRequired,
  selectedState: PropTypes.object.isRequired,
};

export default ExistingIncidents;

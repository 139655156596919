import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, CheckCircle, Edit } from '@pumpkincare/shared/ui';

import styles from './claim-form-accordion.css';

function ClosedWrapper({ isOpen, canEdit, children, onEditClick, ...rest }) {
  return !isOpen && canEdit ? (
    <button onClick={onEditClick} {...rest}>
      {children}
    </button>
  ) : (
    <div {...rest}>{children}</div>
  );
}

ClosedWrapper.propTypes = {
  children: PropTypes.node,
  canEdit: PropTypes.bool,
  isOpen: PropTypes.bool,
  onEditClick: PropTypes.func,
};

function ClaimFormAccordion({
  children,
  onEditClick,
  isComplete,
  isOpen,
  canEdit,
  title,
}) {
  return (
    <ClosedWrapper
      isOpen={isOpen}
      canEdit={canEdit}
      onEditClick={onEditClick}
      className={classNames(styles.root, {
        [styles.hasEdit]: canEdit,
      })}
    >
      {isOpen ? (
        <>
          <h5>{title}</h5>
          {children}
        </>
      ) : (
        <div className={styles.accordionClosed}>
          {isComplete ? (
            <CheckCircle size={20} viewBox='0 0 24 24' className={styles.check} />
          ) : null}

          <Body2
            isBold
            className={classNames(styles.title, {
              [styles.incomplete]: !isComplete,
            })}
          >
            {title}
          </Body2>

          {canEdit ? (
            <div className={styles.edit}>
              <Edit width={20} height={20} />
            </div>
          ) : null}
        </div>
      )}
    </ClosedWrapper>
  );
}

ClaimFormAccordion.defaultProps = {
  onEditClick: () => {},
};

ClaimFormAccordion.propTypes = {
  canEdit: PropTypes.bool,
  children: PropTypes.node,
  isComplete: PropTypes.bool,
  isOpen: PropTypes.bool,
  onEditClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default ClaimFormAccordion;
